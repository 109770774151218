import { createRef } from "react"
import { Vector3 } from "three"

const state = {
  sections: 9,
  pages: 8,
  zoom: 70,
  paragraphs: [
    {
      offset: 1,
      factor: 1.75,
      header: "Sam Francis",
      image: "/SF2.jpg",
      aspect: 0.77,
      text: ""
    },
    {
      offset: 2,
      factor: 2.0,
      header: "Wassily Kandinsky",
      image: "/WK9.jpg",
      aspect: 1,
      text: ""
    },
    {
      offset: 3,
      factor: 2.25,
      header: "Jackson Pollock",
      image: "/JP5.jpeg",
      aspect: 1.5037,
      text: ""
    },
    {
      offset: 4,
      factor: 1.75,
      header: "Al Held",
      image: "/AH3.jpg",
      aspect: 1100 / 825,
      text: ""
    },
    {
      offset: 5,
      factor: 1.75,
      header: "Paul Klee",
      image: "/PK2.jpg",
      aspect: 1.55,
      text: ""
    },
    {
      offset: 7,
      factor: 1.05,
      header: "Willem de Kooning",
      image: "/WdK.jpg",
      aspect: 1.77,
      text: ""
    }
    // {
    //   offset: 8,
    //   factor: 1.05,
    //   header: "Costel Iarca",
    //   image: "/CI.jpg",
    //   aspect: 1.77,
    //   text: ""
    // }
  ],
  stripes: [
    { offset: 0, color: "#000", height: 13 },
    { offset: 6.3, color: "#000", height: 20 }
  ],
  diamonds: [
    { x: 0, offset: 0.15, pos: new Vector3(), scale: 0.6, factor: 1.8 },
    { x: -0.2, offset: 0.8, pos: new Vector3(), scale: 0.5, factor: 2.1 },
    { x: -5, offset: 2, pos: new Vector3(), scale: 0.8, factor: 2.5 },
    { x: 0, offset: 3.2, pos: new Vector3(), scale: 0.8, factor: 1.75 },
    { x: 0, offset: 4, pos: new Vector3(), scale: 0.8, factor: 2.5 },
    { x: 2, offset: 5.5, pos: new Vector3(), scale: 1.25, factor: 0.85 },
    { x: -5, offset: 7, pos: new Vector3(), scale: 0.8, factor: 2 },
    { x: 0, offset: 8, pos: new Vector3(), scale: 1.5, factor: 6 }
  ],
  top: createRef()
}

export default state
